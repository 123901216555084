import { Loading } from 'components/loading'
import { RasaContext } from 'context'
import { utcToZonedTime } from 'date-fns-tz'
import { AjaxWrapper, HttpMethod } from 'generic/ajaxWrapper'
import * as GenericRedux from 'generic/genericRedux'
import * as GenericUtils from 'generic/utility'
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid'
import React, {Component} from 'react'
import {
  ProductSubscription,
} from 'shared_server_client/types/billing_plan'
import { DEFAULT_TIMEZONE } from 'shared_server_client/constants'
import { AccountCancelledSplash } from './cancelled'
import './styles.css'
import * as Flash from '../../../flash';
import { SharedKeys, SharedStore } from '../../../../shared/data-layer/sharedStore';
import { Roles } from '../../../../shared/constants';

type PaymentHistory = any
type PaymentHistoryProps =
     GenericRedux.DatasetComponentProps<PaymentHistory>

interface PaymentHistoryState {
  communityId: string,
  data: any,
  error: boolean,
  isSuperUser: boolean,
  loading: boolean,
  productSubscription: ProductSubscription,
  timezone: string,
}

export class PaymentHistorySection extends Component<PaymentHistoryProps, PaymentHistoryState> {
  public static contextType = RasaContext;

  constructor(props: PaymentHistoryProps) {
    super(props);
    this.state = {
      communityId: null,
      data: null,
      error: false,
      isSuperUser: false,
      loading: true,
      productSubscription: null,
      timezone: null,
    }
  }

  public componentDidMount() {
    const sharedStore = SharedStore.instance(this.context)
    Promise.all([
      sharedStore.getValue(SharedKeys.role),
    ]).then(([role]) => {
      this.setState({isSuperUser : role === Roles.super_admin})
    })
    this.context.user.init().then(({activeCommunity}) => {
      this.setState({
        ...this.state,
        communityId: activeCommunity.communityId,
        productSubscription: activeCommunity.billingInfo.productSubscription,
        timezone: activeCommunity.data && activeCommunity.data.company_time_zone
          ? activeCommunity.data.company_time_zone : DEFAULT_TIMEZONE
      }, () => this.loadData())
    })
  }

  public render() {
    if ( this.isCancelled() ) {
      return <AccountCancelledSplash subscription={this.state.productSubscription}/>
    } else {
      return <div>
              <div className={this.state.loading ? '' : 'hide-element'}>
                <Loading size="32"></Loading>
              </div>
              <div className={this.state.loading ? 'hide-element' : 'payment-history'}>
                <Grid data={this.state.data}>
                  <Column field="invoiceDate" title="Date" format="{0:d}" />
                  <Column field="invoiceNumber" title="Invoice #" width="250px" />
                  <Column field="amount" title="Amount" format="{0:c}" />
                  <Column field="balance" title="Balance" format="{0:c}" />
                  <Column field="receipt" title="Receipt" cell={this.InvoiceDownloadCell} />
                  { this.state.isSuperUser && <Column field="retry" title="Payment Retry" cell={this.paymentRetryCell} /> }
                </Grid>
              </div>
            </div>
    }
  }

  protected InvoiceDownloadCell = (props: any) => {
    return <td><div className='download-icon-container' onClick={() => this.downloadInvoice(props.dataItem)}><i className='fas fa-download'></i></div></td>
  }

  protected paymentRetryCell = (props: any) => {
    const { balance } = props.dataItem
    if (balance === 0) {
      return <td></td>
    }
    return <td><div className='download-icon-container' onClick={() => this.paymentCreate(props.dataItem)}><i className="fa fa-repeat"></i></div></td>
  }

  private paymentCreate = (dataItem: any) => {
    this.setState({
      loading: true,
    })
    const url: string = AjaxWrapper.getServerUrl() +
      `/subscription-billing/create-payment/${this.state.communityId}`
    return AjaxWrapper.ajax(url, HttpMethod.POST, {
      amount: dataItem.balance,
      invoices: [{
        invoice_id: dataItem.invoiceNumber,
        amount_applied: dataItem.balance
      }]
    })
      .then((newData) => {
        const invoices = newData.payload.invoices
        const data = this.state.data.map((invoice: any) =>
          invoice.invoiceNumber === invoices[0].invoice_id ? { ...invoice, balance: invoices[0].balance_amount } : invoice)
        this.setState({
          loading: false,
          data
        })
      })
      .catch((error) => {
        this.setState({
          error: true,
          loading: false,
        })
        this.context.store.dispatch(Flash.showFlashError(error))
      })
  }

  private downloadInvoice(dataItem: any) {
    this.setState({
      loading: true,
    })
    const url: string = AjaxWrapper.getServerUrl() +
      `/subscription-billing/get-customer-invoice/${dataItem.invoiceNumber}/${this.state.communityId}`
    return AjaxWrapper.ajax(url, HttpMethod.GET, {})
    .then((newData) => {
      this.setState({
        loading: false,
      })
      GenericUtils.downloadEncodedFile(newData.payload.fileData, 'pdf', "invoice.pdf")
    })
    .catch((error) => {
      this.setState({
        error: true,
        loading: false,
      })
    })
  }

  private loadData() {
    const url: string = AjaxWrapper.getServerUrl() +
                        `/subscription-billing/get-customer-invoices/${this.state.communityId}`;
    return AjaxWrapper.ajax(url, HttpMethod.GET, {})
      .then((newData) => {
        this.setState({
          loading: false,
          data: newData.payload.map((item) => {
            return {
              ...item,
              invoiceDate: utcToZonedTime(item.invoiceDate, this.state.timezone),
            }
          }),
        })
      })
      .catch((error) => {
        this.setState({
          error: true,
          loading: false,
        })
      })
  }

  private isCancelled() {
    return this.state.productSubscription && this.state.productSubscription.is_cancelled
  }

}
